import { createStore } from "vuex";
// import { useStore } from "vuex";


const store = createStore({
    state() {
        return{
            userData: localStorage.getItem ('userData') || "",
            staticMenuInactive: localStorage.getItem ('staticMenuInactive') || ""
        }
    },
    getters: {
        userData: (state) => {
            return window.atob(state.userData);
        },
        staticMenuInactive: (state) => {
            return window.atob(state.staticMenuInactive);
        },
    },
    actions: {
        setUserData: (context, payload) => {
            context.commit("setUserData", payload);
        },
        removeUserData: context => {
            context.commit("removeUserData");
        },
        setStaticMenuInactive: (context, payload) => {
            context.commit("setStaticMenuInactive", payload);
        },
    },
    mutations: {
        setUserData: (state, payload) => {
            state.userData = window.btoa(payload);
            localStorage.setItem('userData', window.btoa(payload));
        },
        removeUserData: state => {
            state.userData = "";
            localStorage.removeItem('userData');
        },
        setStaticMenuInactive: (state, payload) => {
            state.staticMenuInactive = window.btoa(payload);
            localStorage.setItem('staticMenuInactive', window.btoa(payload));
        },
    }
});

export default store;